<script setup lang="ts">
import type { ComponentBlockReferenceSliderFragment } from "~/types/graphql";

import {
  Enum_Componentblockreferenceslider_Flipped,
  Enum_Componentblockreferenceslider_Variation,
} from "~/types/graphql";

import Arrow from "@/assets/icons/arrow.svg?component";
import type { useSwiper } from "swiper/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Controller } from "swiper/modules";
import "swiper/css";

const props = defineProps<{
  ReferenceSliderPage?: ComponentBlockReferenceSliderFragment["ReferenceSliderPage"];
  ReferenceSliderItem: ComponentBlockReferenceSliderFragment["ReferenceSliderItem"];
  ReferenceSliderFlipped?: ComponentBlockReferenceSliderFragment["ReferenceSliderFlipped"];
  ReferenceSliderVariation?: ComponentBlockReferenceSliderFragment["ReferenceSliderVariation"];
}>();

const { t } = useI18n({
  messages: {
    de: {
      readMore: "Zur Referenz",
    },
    en: {
      readMore: "Reference details",
    },
  },
});
// linkable parent page for blog posts
const page = ref(props.ReferenceSliderPage);
const parentLink = computed(() => {
  return page?.value?.data?.attributes?.Slug
    ? `/${page?.value?.data?.attributes?.Slug}`
    : "";
});

const swiperController: Ref<ReturnType<typeof useSwiper> | null> = ref(null);
const setControlledSwiper = (swiper: ReturnType<typeof useSwiper>) => {
  swiperController.value = swiper;
};
</script>

<template>
  <layout-block
    class="reference-slider"
    :class="{
      'bg-integer-black text-integer-white':
        ReferenceSliderVariation ===
        Enum_Componentblockreferenceslider_Variation.Dark,
      'bg-integer-white text-integer-black':
        ReferenceSliderVariation ===
        Enum_Componentblockreferenceslider_Variation.Light,
    }"
    :outer-space="false"
  >
    <div v-if="parentLink" class="outer-space flex w-full xl:mb-12">
      <nuxt-link
        :to="parentLink"
        class="flex w-fit items-center whitespace-pre text-s font-semibold"
        >{{ ReferenceSliderPage?.data?.attributes?.Name }} <arrow
      /></nuxt-link>
      <module-slider-controls
        v-if="swiperController && ReferenceSliderItem.length > 1"
        type="secondary"
        :variation="ReferenceSliderVariation"
        :swiper-controller="swiperController"
        class="ml-auto w-fit sm:flex xl:hidden"
      />
    </div>

    <swiper
      :modules="[Controller]"
      :css-mode="true"
      class="mt-5 xl:mt-0"
      @swiper="setControlledSwiper"
    >
      <module-slider-controls
        v-if="swiperController && ReferenceSliderItem.length > 1"
        :swiper-controller="swiperController"
        type="secondary"
        :variation="ReferenceSliderVariation"
        class="absolute right-0 top-0 order-3 hidden px-0 xl:flex xl:px-5.5"
      />
      <swiper-slide
        v-for="(item, index) in ReferenceSliderItem"
        ref="referenceSlide"
        :key="`${item?.__typename}-${index}`"
      >
        <div>
          <div class="grid md:grid-cols-2 xl:grid-cols-12">
            <div
              class="outer-space order-2 mt-8 place-self-center justify-self-start text-h2 md:order-1 md:mt-0 xl:max-w-xl"
              :class="
                ReferenceSliderFlipped ===
                Enum_Componentblockreferenceslider_Flipped.Left
                  ? 'md:col-start-2 md:row-start-1 xl:col-span-5 xl:col-start-7'
                  : 'pr-5 xl:col-span-5 xl:col-start-2 xl:pr-0'
              "
            >
              <base-tag
                v-for="(tag, indexTag) in item?.reference?.data?.attributes
                  ?.reference_categories?.data"
                :key="`${tag?.__typename}-${indexTag}`"
                type="primary"
                class="mr-2"
                :class="
                  ReferenceSliderVariation ===
                  Enum_Componentblockreferenceslider_Variation.Dark
                    ? 'border-light-black bg-light-black text-integer-white'
                    : ''
                "
              >
                {{ tag.attributes?.display_name }}
              </base-tag>
              <h2 v-if="item?.headline" class="mt-5">
                {{ item?.headline }}
              </h2>
            </div>
            <base-image
              v-bind="item?.image.data?.attributes"
              :eager-loading="true"
              class="md:outer-space order-1 h-screen-lg w-full object-cover object-center md:order-2 lg:h-screen-xl"
              :class="
                ReferenceSliderFlipped ===
                Enum_Componentblockreferenceslider_Flipped.Left
                  ? 'col-start-1 pr-5 md:row-start-1 xl:col-start-2 xl:col-end-6 xl:pr-0'
                  : 'xl:col-start-8 xl:col-end-12'
              "
              sizes="(min-width: 768px) 50vw, (max-width: 767px) 100vw"
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="outer-space mt-10 flex w-full items-center justify-center">
      <base-button
        :type="
          ReferenceSliderVariation ===
          Enum_Componentblockreferenceslider_Variation.Dark
            ? 'primary'
            : 'secondary'
        "
        class="flex w-full items-center justify-center whitespace-pre xs:w-auto"
        :to="
          parentLink
            ? parentLink +
              '/' +
              ReferenceSliderItem[swiperController?.activeIndex]?.reference
                ?.data?.attributes?.slug
            : ''
        "
        :class="{
          'border-integer-white':
            ReferenceSliderVariation ===
            Enum_Componentblockreferenceslider_Variation.Dark,
        }"
      >
        {{ t("readMore") }}
        <template #icon-right> <arrow /></template>
      </base-button>
    </div>
  </layout-block>
</template>
